import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import styled from 'styled-components'
import LocationIcon from '../images/location-pin-icon.svg'

dayjs.extend(utc)
dayjs.extend(timezone)

/*
 * The <AgendaEventCard> component returns a <li> element and should be used inside an <ol> or <ul>
 * The component should receive an `event` object which must contain:
 * * title
 * * startTime
 * The `event` object can optionally contain:
 * * endTime
 * * handleClick
 * When the component is used with a <ToggleModal>, on click, a modal displays additional event details
 */

/**
 * @example
 * <ArticlesList> // a <ul> element
 *   {events.map(({ node: event }) => (
 *     <AgendaEventCard key={event.id} {...event} />
 *   ))}
 * </ArticlesList>
 *
 * OR as a modal activator
 *
 * <ArticlesList>
 *   {events.map(({ node: event }) => {
 *     return (
 *       <ToggleModal
 *         key={event.id}
 *         toggle={show => (
 *           <AgendaEventCard
 *             handleClick={show}
 *             title={event.title}
 *             startTime={event.startTime}
 *             endTime={event.endTime}
 *           />
 *         )}
 *         content={(hide, isShown) => (
 *           <Modal hide={hide} isShown={isShown}>
 *             <EventModal
 *               event={event}
 *               title={event.title}
 *               body={
 *                 event.body && event.body.childMarkdownRemark.html
 *               }
 *               startTzime={event.startTime}
 *               endTime={event.endTime}
 *             />
 *           </Modal>
 *         )}
 *       />
 *     )
 *   })}
 * </ArticlesList>
 */

const Outer = styled.li`
  grid-column: span 1;
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.colors.lightBorder};
  border-top: 1px solid ${props => props.theme.colors.lightBorder};

  & + & {
    margin-top: -1px;
  }

  > button {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 15px 0;
    text-decoration: none;
    color: inherit;
    text-align: initial;
    cursor: pointer;
  }

  .detail {
    span {
      + span {
        margin-left: 6px;
      }
    }

    svg {
      position: relative;
      bottom: -2px;
      display: inline-block;
      width: 14px;
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    > button {
      padding: 25px 0;
    }
  }
`

const Time = styled.p`
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.colors.accentReunion};

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    /* font-size: 19px;
    letter-spacing: 3px; */
  }
`

const Title = styled.p`
  margin-top: 0;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: calc(
    ${props => props.theme.typography.headlines.medium.lhLg} * 2
  );

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    -webkit-line-clamp: 3;
    max-height: calc(
      ${props => props.theme.typography.headlines.medium.lhLg} * 3
    );
  }
`

const AgendaEventCard = ({
  title,
  startTime,
  endTime,
  location,
  handleClick,
  timeZone,
}) => {
  return (
    <Outer>
      <button onClick={handleClick} title={title}>
        <Time>
          {startTime && endTime
            ? endTime && startTime === endTime
              ? 'TBD'
              : timeZone
                ? dayjs(new Date(startTime))
                  .tz(timeZone)
                  .format('h:mm A')
                : dayjs(startTime).format('h:mm A z')
            : 'All Day'}
        </Time>
        <Title style={{ lineHeight: title === 'Live at the Matinee: Lunch Break' ? '20px' : '' }} title={title}>{title}</Title>
        {title === 'Live at the Matinee: Lunch Break' && (
          <p style={{ fontStyle: 'italic', margin: '0 0 6px', lineHeight: '20px' }}>
            {new Date(startTime).getDay() === 5 ? 'Update Product Store + Experience' : 'Pickup presale collections'}<br />
            Foundation Activity<br />
            Glow-Up Competition
          </p>
        )}
        <p className="detail">
          {!!endTime && startTime !== endTime && (
            <span>
              {timeZone
                ? dayjs(new Date(startTime))
                  .tz(timeZone)
                  .format('h:mm A')
                : dayjs(startTime).format('h:mm A')}
              {!!endTime &&
                startTime !== endTime &&
                ` – ${timeZone
                  ? dayjs(new Date(endTime))
                    .tz(timeZone)
                    .format('h:mm A')
                  : dayjs(endTime).format('h:mm A')
                }`}
            </span>
          )}
          {location && (
            <span>
              <LocationIcon />
              {location}
            </span>
          )}
        </p>
      </button>
    </Outer>
  )
}

export default AgendaEventCard
