import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import styled from 'styled-components'
//import venueMap from '../../static/images/venue-map.png'
import LocationIcon from '../images/location-pin-icon.svg'
import CalendarICS from './CalendarICS'

dayjs.extend(utc)
dayjs.extend(timezone)

/*
 * The <EventModal> should receive an `event` object which must contain:
 * * title
 * * startTime
 * The `event` object can optionally contain:
 * * endTime
 * * body
 * This `event` object will be passed to a <CalendarICS> child component of <EventModal>
 * The component also received event data as props which must include:
 * * title
 * * startTime
 * And optionally contain the props:
 * * endTime
 * * body
 * The component is used inside a <Modal>, which is inside a <ToggleModal> that will trigger this component to be shown
 */

/**
 * @example
 * <ArticlesList>
 *   {events.map(({ node: event }) => {
 *     return (
 *       <ToggleModal
 *         key={event.id}
 *         toggle={show => (
 *           <EventCard
 *             handleClick={show}
 *             title={event.title}
 *             startTime={event.startTime}
 *             endTime={event.endTime}
 *           />
 *         )}
 *         content={(hide, isShown) => (
 *           <Modal hide={hide} isShown={isShown}>
 *             <EventModal
 *               event={event}
 *               title={event.title}
 *               body={
 *                 event.body && event.body.childMarkdownRemark.html
 *               }
 *               startTime={event.startTime}
 *               endTime={event.endTime}
 *             />
 *           </Modal>
 *         )}
 *       />
 *     )
 *   })}
 * </ArticlesList>
 */

const Outer = styled.article`
  > a {
    margin-top: 20px;
  }

  .btn--pill {
    min-width: 0;
    padding: 10px 15px;
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    > a {
      margin-top: 30px;
    }
  }
`

const Spacer = styled.span`
  float: right;
  width: 10px;
  height: 5px;

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    display: none;
  }
`

const Title = styled.h3`
  margin-bottom: 20px;

  @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
  }
`

const LocationText = styled.p`
  margin-bottom: 10px;

  svg {
    position: relative;
    bottom: -2.5px;
    width: 16px;
    margin-left: -2px;
    margin-right: 2px;
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    margin-bottom: 15px;
  }
`

const EventDate = styled.p``
const Description = styled.div`
  margin-top: 30px;

  strong {
    font-weight: 600;
  }
`

const EventModal = ({
  event,
  title,
  startTime,
  endTime,
  body,
  location,
  timeZone,
  reunion,
}) => {
  return (
    <Outer className={reunion ? 'artist-reunion' : null}>
      <Spacer />
      <Title className="headline--medium" title={title}>
        {title}
      </Title>
      {location ? (
        <LocationText className="label">
          <LocationIcon />
          {location}
        </LocationText>
      ) : null}
      <EventDate className="detail">
        {!!endTime && startTime === endTime
          ? `${dayjs(startTime).format('MMMM DD, YYYY')} @ TBD`
          : endTime
          ? `${dayjs(startTime).format('MMMM DD, YYYY')} @ ${
              timeZone
                ? dayjs(new Date(startTime)).tz(timeZone).format('h:mm A')
                : dayjs(startTime).format('h:mm A')
            } – ${
              timeZone
                ? dayjs(new Date(endTime)).tz(timeZone).format('h:mm A')
                : dayjs(endTime).format('h:mm A')
            }`
          : 'All-Day Event'}
      </EventDate>
      {body && (
        <Description
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html,
          }}
        />
      )}
      {endTime && startTime !== endTime && (
        <CalendarICS
          className="btn--pill"
          event={event}
          downloadBtnText="Add to Calendar"
        />
      )}
      {/* reunion && location && (
        <Description>
          <p className="label">
            Resort Map
            <br />
            <img src={venueMap} alt="Resort Map" />
          </p>
        </Description>
      ) */}
    </Outer>
  )
}

export default EventModal
