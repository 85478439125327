import dayjs from 'dayjs'
import React from 'react'
import styled from 'styled-components'
import theme from '../styles/theme'

/*
 * The <EventCard> component returns a <li> element and should be used inside an <ol> or <ul>
 * The component should receive an `event` object which must contain:
 * * title
 * * startTime
 * The `event` object can optionally contain:
 * * endTime
 * * handleClick
 * * showMonth
 * When the component is used with a <ToggleModal>, on click, a modal displays additional event details
 */

/**
 * @example
 * <ArticlesList> // a <ul> element
 *   {events.map(({ node: event }) => (
 *     <EventCard key={event.id} {...event} />
 *   ))}
 * </ArticlesList>
 *
 * OR as a modal activator
 *
 * <ArticlesList>
 *   {events.map(({ node: event }) => {
 *     return (
 *       <ToggleModal
 *         key={event.id}
 *         toggle={show => (
 *           <EventCard
 *             handleClick={show}
 *             title={event.title}
 *             startTime={event.startTime}
 *             endTime={event.endTime}
 *           />
 *         )}
 *         content={(hide, isShown) => (
 *           <Modal hide={hide} isShown={isShown}>
 *             <EventModal
 *               event={event}
 *               title={event.title}
 *               body={
 *                 event.body && event.body.childMarkdownRemark.html
 *               }
 *               startTzime={event.startTime}
 *               endTime={event.endTime}
 *             />
 *           </Modal>
 *         )}
 *       />
 *     )
 *   })}
 * </ArticlesList>
 */

const titleClass = 'headline--xs'
const titleLHSm = theme.typography.headlines.xs.lhSm
const titleLHLg = theme.typography.headlines.xs.lhLg

const Outer = styled.li`
  /* grid-column: span 1; */
  border-top: 1px solid ${props => props.theme.colors.lightBorder};
  border-bottom: 1px solid ${props => props.theme.colors.lightBorder};
  -webkit-tap-highlight-color: ${props =>
    props.theme.colors.webkitTapHighlight};

  & + & {
    margin-top: -1px;
  }

  > button {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 18px 0;
    text-decoration: none;
    color: inherit;
    text-align: initial;
    cursor: pointer;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    > button {
      padding: 25px 0;
    }
  }
`

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 60px;
  flex: 0 0 auto;
  margin-right: 6px;
  text-align: center;

  /* > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  } */
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    width: 122px;
    margin-right: 30px;

    .label {
      font-size: 26px;
      line-height: 26px;
    }
  }
`

const MonthDate = styled.p`
  display: inline-flex;
  margin-top: 0;
  font-size: 32px; // Refactor to use theme variables
  line-height: 32px; // Refactor to use theme variables
  font-weight: 300;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    font-size: 60px; // Refactor to use theme variables
    line-height: 60px; // Refactor to use theme variables
    margin-top: 4px; // Refactor to use theme variables
  }
`

const Day = styled.p`
  display: inline-flex;
  margin-bottom: 2px;
  /* margin: 0; */
  /* text-transform: uppercase; */
  /* font-weight: 600; */
  /* letter-spacing: 1px; */
  /* color: ${props => props.theme.colors.primary}; */

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    /* font-size: 26px;
    line-height: 36px;
    letter-spacing: 1.5px; */
  }
`

const DetailsWrapper = styled.div`
  width: 100%;
  /* display: flex; */
  /* flex: 1; */
  /* flex-direction: column; */
  /* align-items: flex-start; */
  /* padding-left: 20px; */

  .${titleClass} {
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: calc(${titleLHSm} * 2);
  }

  .btn--pill {
    float: right;
    min-width: 0;
    margin-left: 20px;
    background-color: white;
    cursor: pointer;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    .btn--pill {
      padding-left: 30px;
      padding-right: 30px;
    }

    .${titleClass} {
      -webkit-line-clamp: 3;
      max-height: calc(${titleLHLg} * 3);
      }
    }
  }
`

const EventCard = ({ title, startTime, endTime, handleClick, showMonth }) => {
  return (
    <Outer>
      <button onClick={handleClick} title={title}>
        <DateWrapper>
          <Day className="label">
            {showMonth
              ? dayjs(startTime).format('MMM')
              : dayjs(startTime).format('ddd')}
          </Day>
          <MonthDate>{dayjs(startTime).format('D')}</MonthDate>
        </DateWrapper>
        <DetailsWrapper>
          <span className="btn--pill show-md-up">Details</span>
          <h4 className={titleClass} title={title}>
            {title}
          </h4>
          <p className="detail">
            {startTime && endTime
              ? dayjs(startTime).format('h:mm A')
              : 'All-Day Event'}
            {endTime && ` – ${dayjs(endTime).format('h:mm A')}`}
          </p>
        </DetailsWrapper>
      </button>
    </Outer>
  )
}

export default EventCard
