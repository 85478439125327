import React from 'react'
import styled from 'styled-components'
import AgendaEventCard from './AgendaEventCard'
import EventCard from './EventCard'
import EventModal from './EventModal'
import Modal from './Modal'
import ToggleModal from './ToggleModal'

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;
  width: 100%;
  max-width: ${props => props.theme.containerWidths.medium};
  margin: 0 auto 10px;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
  }
`

const EventCards = ({ events, showMonth, agendaEvents, timeZone, reunion }) => (
  <List agendaEvents={agendaEvents}>
    {events.map(({ node: event }) => (
      <ToggleModal
        key={event.id}
        toggle={show =>
          agendaEvents ? (
            <AgendaEventCard
              reunion={reunion}
              timeZone={timeZone}
              handleClick={show}
              {...event}
            />
          ) : (
            <EventCard showMonth={showMonth} handleClick={show} {...event} />
          )
        }
        content={(hide, isShown) => (
          <Modal hide={hide} isShown={isShown}>
            <EventModal
              event={event}
              {...event}
              reunion={reunion}
              timeZone={timeZone}
            />
          </Modal>
        )}
      />
    ))}
  </List>
)

export default EventCards
